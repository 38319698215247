import _ from 'underscore'

export default function generateTimeslots(segments = 2) {
  return _.flatten(
    _.map(
      _.range(24),
      (hour) => {
        hour = `0${hour}`.slice(-2)

        let parts = []

        let interval = Math.floor(60 / segments)
        let current  = 0
        while(current < 60) {
          parts.push(`${hour}:` + `0${current}`.slice(-2))
          current += interval
        }

        return parts
      }
    )
  )
}
