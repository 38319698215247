import _ from 'underscore'
import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = ['input']

  static values = {
    clickToShowLabel: { type: String, default: 'Add comment' }
  }

  get elementID() {
    if (this.element.id) {
      return this.element.id
    } else {
      let randomId = `click-to-show-target-${parseInt(Math.random() * 1000000)}`
      this.element.id = randomId
      return randomId
    }
  }

  get parent() {
    return this.element.parentElement
  }

  get wrapper() {
    let previous = this.element.previousElementSibling
    if (previous && previous.classList.contains('click-to-show-wrapper')) {
      return previous
    }
  }

  connect () {
    _.defer(() => this.initializeComment())
  }

  initializeComment() {
    if (!this.wrapper) {
      let tagName = this.element.tagName
      let wrapper = document.createElement(tagName)
      wrapper.classList.add('click-to-show-wrapper')
      this.morph(
        wrapper,
        `<a href="#${this.elementID}" class="click-to-show-trigger">${this.clickToShowLabelValue}</a>`
      )

      this.parent.insertBefore(wrapper, this.element)

      wrapper.addEventListener('click', this.show.bind(this))
    }

    if (this.inputTarget.value.length > 0) {
      this.show()
    } else {
      this.hide()
    }
  }

  show(event) {
    if (event) { event.preventDefault() }

    if (this.wrapper) {
      this.wrapper.classList.add('is-hidden')
    }

    this.element.classList.remove('is-hidden')

    if (this.embedded) {
      this.embedded.reportWidgetSize()
    }
  }

  hide(event) {
    if (event) { event.preventDefault() }

    if (this.wrapper) {
      this.wrapper.classList.remove('is-hidden')
    }

    this.element.classList.add('is-hidden')

    if (this.embedded) {
      this.embedded.reportWidgetSize()
    }
  }
}
