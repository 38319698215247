import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    this.guest_details = this.element.querySelector('#guest-details');
    this.guest_allergies = this.element.querySelector('#guest-allergies');
    this.guest_diets = this.element.querySelector('#guest-diets');
    this.toggleRestrictionIcons()
  }

  toggleRestrictionIcons() {
    this.element.querySelectorAll('.guest-selected-restrictions').forEach(item => {
      const restriction_count = item.querySelectorAll('.restriction');
      const closest_link = item.querySelectorAll('a')[0]

      if (restriction_count.length) {
        closest_link.classList.remove('add-restriction');
        closest_link.classList.add('edit-restriction');
      } else {
        closest_link.classList.remove('edit-restriction');
        closest_link.classList.add('add-restriction');
      }
    });
  }

  showAllergiesPanel(event) {
    event.preventDefault()
    this.guest_details.classList.add('is-hidden')
    this.guest_allergies.classList.remove('is-hidden')
  }

  showDietsPanel(event) {
    event.preventDefault()
    this.guest_details.classList.add('is-hidden')
    this.guest_diets.classList.remove('is-hidden')
  }

  showDetailsPanel(event) {
    event.preventDefault()
    this.guest_diets.classList.add('is-hidden')
    this.guest_allergies.classList.add('is-hidden')
    this.guest_details.classList.remove('is-hidden')
  }
}
