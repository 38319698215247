import I18n from 'i18n-js'

// Set translations
I18n.translations = ""

// Set the current locale:
I18n.locale = document.querySelector('html').dataset.locale
I18n.currentLocale()

// Make I18n available to legacy scripts in app/assets:
window.I18n = I18n

export default I18n
