import ApplicationController from 'controllers/application_controller'
import flatpickr             from 'flatpickr'
import _                     from 'underscore'
import { English }           from 'flatpickr/dist/l10n/default'
import { Dutch   }           from 'vendor/flatpickr/l10n/nl'
import { German  }           from 'flatpickr/dist/l10n/de'
import { French  }           from 'flatpickr/dist/l10n/fr'

const LOCALE_MAPPINGS = {
  en: English,
  nl: Dutch,
  de: German,
  fr: French
}

export default class extends ApplicationController {

  static targets = [
    'input',
    'monthSelectWrapper'
  ]

  static values = {
    locale: { type: String, default: 'en' }
  }

  get value() {
    if (this.hasInputTarget) {
      if (this.inputTarget.value && this.inputTarget.value.length) {
        return new Date(this.inputTarget.value)
      } else {
        return null
      }
    } else if (this.flatpickr) {
      return this.flatpickr.selectedDates[0]
    }
  }

  set value(newValue) {
    if (this.flatpickr) {
      if (newValue) {
        this.flatpickr.setDate(newValue)
      } else {
        this.flatpickr.clear()
      }
    } else if (this.hasInputTarget) {
      this.inputTarget.value = newValue
    }
  }

  get flatpickrElement () {
    if (this.flatpickr && this.flatpickr.element) {
      return this.flatpickr.element
    } else if (this.hasInputTarget) {
      return this.inputTarget
    } else {
      return this.element
    }
  }

  // SETUP:

  initialize () {
    this.flatpickrOptions = {}
  }

  connect () {
    this.flatpickr = flatpickr(
      this.inputTarget,
      {
        locale:        (LOCALE_MAPPINGS[this.localeValue] || English),
        disableMobile: true,
        minDate:       new Date(new Date().setDate(new Date().getDate() - 1)),
        disable:       [this.isDisabledDate.bind(this)],
        onChange:      [this.datePickerChanged.bind(this)],
        onOpen:        [this.datePickerOpened.bind(this)],
        onClose:       [this.datePickerClosed.bind(this)],
        onMonthChange: [this.datePickerMonthChanged.bind(this)],
        onYearChange:  [this.datePickerYearChanged.bind(this)],
        onReady:       [this.datePickerReady.bind(this)],
        onValueUpdate: [this.datePickerValueUpdated.bind(this)],
        onDayCreate:   [this.createDatePickerDay.bind(this)],
        ...this.flatpickrOptions
      }
    )
  }

  disconnect () {
    this.flatpickr.destroy()
    this.flatpicker = null
  }

  // MONTH SELECT HACKS:

  wrapMonthSelect(flatpickr = this.flatpickr) {
    if (this.hasMonthSelectWrapperTarget) { return }

    let monthSelect = flatpickr.monthElements[0]
    if (monthSelect) {
      let wrapper = document.createElement('div')
      wrapper.className = 'flatpickr-select-wrapper'
      wrapper.setAttribute(`data-${this.identifier}-target`, 'monthSelectWrapper')
      monthSelect.parentNode.insertBefore(wrapper, monthSelect)
      wrapper.appendChild(monthSelect)
    }
  }

  resizeMonthSelect (flatpickr = this.flatpickr) {
    if (!this.hasMonthSelectWrapperTarget) { return }

    let select  = flatpickr.monthElements[0]
    let options = Array.from(select.options)
    let wrapper = this.monthSelectWrapperTarget

    // Create a ruler element to measure the width of the selected <option>'s text
    let rulerElement = document.createElement('div')
    rulerElement.className   = 'flatpickr-month-ruler'
    rulerElement.textContent = options.find(
      (option) => option.value == flatpickr.currentMonth
    ).innerHTML

    // Append the ruler element and measure its width
    wrapper.appendChild(rulerElement)
    let rulerWidth = Math.ceil(rulerElement.getBoundingClientRect().width)

    // Apply the width to the wrapper and the select
    wrapper.style.width = `${rulerWidth}px`
    select.style.width  = `${rulerWidth}px`

    // Remove the ruler element
    wrapper.removeChild(rulerElement)
  }

  // DATE PICKER CALLBACKS:

  isDisabledDate (date) {
    return this.bookable.isDisabledDate(date)
  }

  // DATE PICKER EVENTS:

  /*
   * EVENTS:
   *
   * flatpickr features event hooks for the most common and useful actions. For each
   * hook, you may specify a single function, or an array of functions.
   *
   * After instantiation, all hooks can be accessed via the instance’s config object.
   * Inside the object, all functions are stored in arrays, so you would need to
   * manipulate the array itself to add or remove functions:
   *
   * Example: this.flatpickr.config.onChange.push(function() { } )
   *
   * Each function added to a hook will receive 3 arguments when called. These are:
   *
   *  1. `selectedDates` - an array of Date objects selected by the user. When there
   *     are no dates selected, the array is empty.
   *  2. `dateAsString` - a string representation of the latest selected Date object
   *     by the user. The string is formatted as per the dateFormat option.
   *  3. `flatpickr` - the flatpickr object, containing various methods and properties.
   *
   * See: https://flatpickr.js.org/events/
   */

  // onChange gets triggered when the user selects a date, or changes the time on a selected date.
  datePickerChanged (selectedDates, dateAsString, flatpickr) {
  }

  // Gets triggered when the calendar is opened.
  datePickerOpened (selectedDates, dateAsString, flatpickr) {
    this.resizeMonthSelect(flatpickr)
  }

  // Gets triggered when the calendar is closed.
  datePickerClosed (selectedDates, dateAsString, flatpickr) {
  }

  // Gets triggered when the month is changed, either by the user or programmatically.
  datePickerMonthChanged (selectedDates, dateAsString, flatpickr) {
    _.defer(() => this.resizeMonthSelect(flatpickr))
  }

  // Gets triggered when the year is changed, either by the user or programmatically.
  datePickerYearChanged (selectedDates, dateAsString, flatpickr) {
    _.defer(() => this.resizeMonthSelect(flatpickr))
  }

  // Gets triggered once the calendar is in a ready state.
  datePickerReady (selectedDates, dateAsString, flatpickr) {
    this.wrapMonthSelect(flatpickr)
  }

  // Gets triggered when the input value is updated with a new date string.
  datePickerValueUpdated (selectedDates, dateAsString, flatpickr) {
  }

  // Take full control of every date cell by interacting with dayElement.
  createDatePickerDay (selectedDates, dateAsString, flatpickr, dayElement) {
    dayElement.dataset.date = flatpickr.formatDate(dayElement.dateObj, 'Y-m-d')
  }

  // PRIVATE:


}
