import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static values = {
    targetSelectorId: String,
    otherChoiceTargetSelectorId: String,
    otherChoiceIsChecked: Boolean
  }

  connect() {
    this.element.addEventListener("change", this.handleCheckboxChange.bind(this));
  }

  tagList() {
    return document.getElementById(this.targetSelectorIdValue);
  }

  addTag(value, name) {
    const span_element = document.createElement('span');
    span_element.className = 'restriction';
    span_element.textContent = name;

    const list_item = document.createElement('li');
    list_item.setAttribute('data-tag-value', value)
    list_item.appendChild(span_element)

    this.tagList().insertBefore(list_item, this.tagList().firstChild);
  }

  removeTag(value) {
    const selected_element = this.tagList().querySelector(`li[data-tag-value="${value}"]`);
    if (selected_element) selected_element.remove()
  }

  handleCheckboxChange(event) {
    const checkbox = event.target;

    if (checkbox.checked) {
      this.addTag(checkbox.value, checkbox.closest('label').textContent.trim())
    } else {
      this.removeTag(checkbox.value)
    }

    this.findParentController('invite-form').toggleRestrictionIcons()
  }
}
