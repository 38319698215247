import ApplicationController from 'controllers/application_controller'
import {isFinite} from "underscore";
import RealtimeWidgetController from 'controllers/realtime_widget_controller'

export default class extends ApplicationController {

  static values = {
    ranges: Object
  }

  static outlets = ['realtime-widget']

  /**
   * @returns {RealtimeWidgetController}
   */
  get widget() {
    return this.realtimeWidgetOutlet
  }

  get offerID () {
    return this.widget.booking.idValue
  }

  get useDefaultDeposit () {
    return this.offerID === 0
  }

  get totalCost () {
    if (this.useDefaultDeposit) {
      return this.rangesValue['default']['values'][this.widget.booking.guests];
    } else {
      return this.rangesValue['offers'][this.offerID]['values'][this.widget.booking.guests];
    }
  }

  get costPerPerson () {
    if (this.useDefaultDeposit) {
      return this.rangesValue['default']['values'][1];
    } else {
      return this.rangesValue['offers'][this.offerID]['values'][1];
    }
  }

  get costPerPersonInCents () {
    if (this.useDefaultDeposit) {
      return this.rangesValue['default']['default'];
    } else {
      return this.rangesValue['offers'][this.offerID]['default'];
    }
  }

  toggleDepositInfo() {
    if (this.costPerPersonInCents === 0) {
      this.widget.depositWarningTarget.classList.add('is-hidden')
      this.widget.reservationPaymentTarget.classList.add('is-hidden')
    } else {
      this.widget.depositWarningTarget.classList.remove('is-hidden')
      this.widget.reservationPaymentTarget.classList.remove('is-hidden')
    }
  }

  updateDescription () {
    this.widget.depositNumberOfGuestsTarget.innerText = this.widget.booking.guests
    this.widget.depositTotalCostTarget.innerText = this.totalCost
    this.widget.depositCostPerPersonTargets.forEach(elem => elem.innerText = this.costPerPerson)
    this.toggleDepositInfo()
  }
}
