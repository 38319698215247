import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static values = {
    targetSelectorId: String
  }

  connect() {
    this.element.addEventListener("change", this.handleChange.bind(this));
    this.target = document.getElementById(this.targetSelectorIdValue)
  }

  handleChange(event) {
    const input_value = event.target.value;

    if (input_value) {
      this.target.textContent = input_value
      this.target.parentElement.classList.remove('is-hidden')
    } else {
      this.target.textContent = ''
      this.target.parentElement.classList.add('is-hidden')
    }

    this.findParentController('invite-form').toggleRestrictionIcons()
  }
}
