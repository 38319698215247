import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['input']

  static classes = ['isCopied']

  static values = {
    title: { type: String, default: 'Reservation'           },
    text:  { type: String, default: 'View your reservation' }
  }

  get shareURL () {
    if (this.hasInputTarget) {
      return this.inputTarget.value
    }
  }

  copyLink(event) {
    event.preventDefault()

    this.inputTarget.select()
    navigator.clipboard.writeText(this.shareURL)
    this.inputTarget.parentElement.classList.add(this.isCopiedClass)

    // Remove the class again after 1.5 seconds
    setTimeout(() => this.inputTarget.parentElement.classList.remove(this.isCopiedClass), 1500);
  }

  shareLink(event) {
    event.preventDefault()

    navigator.share({
      title: this.titleValue,
      text:  this.textValue,
      url:   this.shareURL
    })
  }
}
